import Password from "../components/Password"
import HomeLayout from "../components/[przyjaznedeklaracje]/_homeLayout"

import Variables from "../variables/variables.json"

export default function Index(props) {
  let Miasto = "PRZYJAZNEDEKLARACJE"
  let Podatek = "index"

  return Variables.miasta[Miasto].password ? (
    <Password>
      <HomeLayout miasto={Miasto} podatek={Podatek}></HomeLayout>
    </Password>
  ) : (
    <HomeLayout miasto={Miasto} podatek={Podatek}></HomeLayout>
  )
}
