import { NextSeo } from "next-seo";
import { SetPassword } from "../hook/SetPassword";

const Password = (props) => {
	const [password, checkPassword] = SetPassword();

	const registerUser = (event) => {
		let login = event.target.login.value;
		let passwords = event.target.password.value;
		let error = document.getElementsByClassName("pd-password__error")[0];
		event.preventDefault();
		if (login == "o4bview" && passwords == "adur81#") {
			checkPassword();
		} else {
			error.style.display = "block";
			error.ariaHidden = false;
		}
	};

	return !password ? (
		<>
			<NextSeo noindex="true" nofollow="true" />
			<section className="pd-password">
				<h2 className="pd-password__title">Logowanie</h2>
				<form className="pd-password__panel" onSubmit={registerUser}>
					<div className="pd-password__login">
						<label htmlFor="login">Użytkownik:</label>
						<input id="login" type="text" autoComplete="login" required />
					</div>
					<div className="pd-password__password">
						<label htmlFor="password">Hasło:</label>
						<input id="password" type="password" autoComplete="password" required />
					</div>
					<div className="pd-password__action">
						<button type="submit" className="pd-btn pd-btn--primary pd-btn--full">
							Zaloguj się
						</button>
					</div>
					<div className="pd-password__error" aria-hidden="true">
						<p>Złe hasło. Spróbuj ponownie.</p>
					</div>
				</form>
			</section>
		</>
	) : (
		props.children
	);
};

export default Password;
